<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/companies/add">
            <button class="btn btn-primary">
              {{ $t('Add a company') }}
              <i class="mdi mdi-plus-thick"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <h6 class="open-search">
                  البحث
                  <i v-if="is_searching" class="fas fa-search text-primary font-18 float-end"></i>
                  <i v-else class="fas fa-search text-primary font-18 float-end"></i>
                </h6>
              </div>
            </div>
            <div v-if="is_searching" class="row">
              <div class="col-md-4">
                <md-field md-inline>
                  <label>معرف الشركة</label>
                  <md-input v-model="searchData.company_id"></md-input>
                </md-field>
              </div>
              <div class="col-md-4">
                <md-field md-inline>
                  <label>اسم الشركة</label>
                  <md-input v-model="searchData.company_name"></md-input>
                </md-field>
              </div>
              <div class="col-md-4">
                <md-field md-inline>
                  <label>البريد الالكتروني</label>
                  <md-input v-model="searchData.email"></md-input>
                </md-field>
              </div>
              <div class="col-md-4">
                <md-field>
                  <label>رمز البلد</label>
                  <md-select v-model="searchData.phone_country_code">
                    <md-option v-for="(item, index) in CountryCodeDetails" :key="index" :value="item.dial_code">
                      {{ $i18n.locale == 'ar' ? item.name : item.en_name }} ({{ item.dial_code }})
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="col-md-4">
                <md-field md-inline>
                  <label>رقم الجوال</label>
                  <md-input v-model="searchData.phone"></md-input>
                </md-field>
              </div>
              <div class="col-md-4">
                <md-datepicker v-model="searchData.date">
                  <label>تاريخ الإنضمام</label>
                </md-datepicker>
              </div>
              <div class="col-md-12 text-right">
                <button class="btn btn-primary br-4 pl-4 pr-4" type="button" @click="search">
                  تطبيق
                </button>
                <button class="btn btn-dark br-4 pl-4 pr-4 ml-3" @click="clearSearch" type="button">
                  مسح
                </button>
                <button class="btn btn-warning br-4 pl-4 pr-4 ml-3" @click="resetSearch" type="button">
                  إعادة ضبط
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="table-responsive mb-0">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
              <div class="col-md-12 d-none2 table-div">
                <md-table v-model="tableData" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="المعرف" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="اسم الشركة">
                      {{ item.company_name }}
                    </md-table-cell>
                    <md-table-cell md-label="البريد الإلكتروني">
                      {{ item.email }}
                    </md-table-cell>
                    <md-table-cell md-label="رقم الجوال" style="direction: ltr;">
                      {{ item.phone_country_code }} {{ item.phone }}
                    </md-table-cell>
                    <md-table-cell md-label="تاريخ الإنضمام">
                      <span v-if="companies_count != 0">
                        {{ new Date(item.created_at).toLocaleDateString('en-US',
                          { day: '2-digit' }) }} /
                        {{ new
                          Date(item.created_at).toLocaleDateString('en-US', {
                            month: '2-digit'
                          }) }} /
                        {{ new Date(item.created_at).getFullYear() }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="companies_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <md-menu-item :href="'/companies/show/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item @click="loginAsAdmin(item.id)">
                            الدخول كمسؤول
                          </md-menu-item>
                          <md-menu-item :href="'/companies/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteCompany(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item>
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="companies_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>
            </div>
            <div v-if="companies_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import countryCode from '@/assets/file/country-code.json';
export default {
  page: {
    title: "companies",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      tableData: [{}, {}, {}, {}, {}, {}, {}, {}],
      CountryCodeDetails: [],
      title: "companies",
      currentPage: 1,
      rows: null,
      perPage: 10,
      companies_count: 0,
      is_searching: false,
      searchData: {
        company_id: '',
        company_name: '',
        phone_country_code: '',
        phone: '',
        email: '',
        date: null,
      }
    };
  },
  mounted() {
    that = this;
    this.jquery();
    $('.page-loader-parent').show();
    $('.table-div').hide();
    this.CountryCodeDetails = countryCode;
    var url = `${this.$appUrl}/api/admin/companies?perPage=${this.perPage}&page=${this.currentPage}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      var res = response.data.companies.data;
      this.rows = response.data.companies.total;
      this.companies_count = res.length;
      if (res.length > 0) {
        this.tableData = response.data.companies.data;
      } else {
        this.tableData = [{}, {}, {}, {}, {}, {}, {}, {}];
      }
      $('.page-loader-parent').hide();
      $('.table-div').show();
    });
  },
  methods: {
    jquery() {
      $(document).on('click', '.open-search', function () {
        if (that.is_searching) {
          that.is_searching = false;
        } else {
          that.is_searching = true;
        }
      });
    },
    search() {
      $('.page-loader-parent').show();
      $('.table-div').hide();
      if (this.searchData.date != null) {
        this.searchData.date = new Date(this.searchData.date).getFullYear() + '-' + new
          Date(this.searchData.date).toLocaleDateString('en-US', {
            month: '2-digit'
          })
          + '-' + new Date(this.searchData.date).toLocaleDateString('en-US',
            { day: '2-digit' });
      }
      var searchData = JSON.stringify(this.searchData);
      var url = `${this.$appUrl}/api/admin/companies?perPage=${this.perPage}&page=${this.currentPage}&searchData=${searchData}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        var res = response.data.companies.data;
        this.rows = response.data.companies.total;
        this.companies_count = res.length;
        if (res.length > 0) {
          this.tableData = response.data.companies.data;
        } else {
          this.tableData = [{}, {}, {}, {}, {}, {}, {}, {}];
        }
        $('.page-loader-parent').hide();
        $('.table-div').show();
      });
    },
    resetSearch() {
      this.searchData = {
        company_id: '',
        company_name: '',
        phone_country_code: '',
        phone: '',
        email: '',
        date: null,
      };
      $('.page-loader-parent').show();
      $('.table-div').hide();
      var url = `${this.$appUrl}/api/admin/companies?perPage=${this.perPage}&page=${this.currentPage}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        var res = response.data.companies.data;
        this.rows = response.data.companies.total;
        this.companies_count = res.length;
        if (res.length > 0) {
          this.tableData = response.data.companies.data;
        } else {
          this.tableData = [{}, {}, {}, {}, {}, {}, {}, {}];
        }
        $('.page-loader-parent').hide();
        $('.table-div').show();
      });
    },
    clearSearch() {
      this.searchData = {
        company_id: '',
        company_name: '',
        phone_country_code: '',
        phone: '',
        email: '',
        date: null,
      };
    },
    loginAsAdmin(id) {
      var url = `${this.$appUrl}/api/admin/companies/get_token/${id}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        var token = response.data.access_token;
        const newTab = window.open(`${this.$appBusinessUrl}/business/dashboard?business_access_token=${token}`, '_blank');
        newTab.focus();
      });
    },
    encodeId(id) {
      return btoa(id * 987654321);
    },
    deleteCompany(id) {
      this.$swal.fire({
        title: 'حذف الشركة',
        text: "هل ترغب بحذف الشركة؟",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#196dfb',
        cancelButtonColor: '#999999',
        confirmButtonText: 'نعم',
        cancelButtonText: 'إلغاء',
      }).then((result) => {
        if (result.isConfirmed) {
          $('.pre-loader').show();
          var url = `${this.$appUrl}/api/admin/companies/delete/${id}?lang=${this.$i18n.locale}`;
          this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
            if (response.data.status == 1) {
              $('.pre-loader').hide();
              this.$swal.fire({
                title: 'تمت العملية بنجاح',
                text: 'تم حذف الشركة',
                icon: 'success',
                confirmButtonColor: '#196dfb',
                confirmButtonText: 'الموافقة'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                } else {
                  window.location.reload();
                }
              });
            } else {
              $('.pre-loader').hide();
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                confirmButtonColor: '#196dfb',
                text: response.data.message,
              })
            }
          });
        }
      });
    },
    handlePagination() {
      var url = `${this.$appUrl}/api/admin/companies?page=${this.currentPage}&perPage=${this.perPage}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        this.tableData = response.data.companies.data;
        this.rows = response.data.companies.total;
      });
    },
  }
};
</script>
<style lang="scss">
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}

.md-icon.md-theme-default.md-icon-image svg {
  display: none;
}

.card {
  .md-field>.md-icon:after {
    background-color: rgb(0 0 0 / 0%);
  }
}

.open-search {
  cursor: pointer;
  padding: 5px 0px;
  margin: 0px;
}
</style>